import { ToastPayload, toastEventBusyKey } from '../types/toast';
import { useDevice, useEventBus } from '#imports';

export const useToast = (group?: string) => {
  const device = useDevice();
  const bus = useEventBus(toastEventBusyKey);

  return {
    show: (payload: ToastPayload) =>
      bus.emit({ name: 'open', payload: { compact: device.isMobile, ...payload, ...(group && { group }) } }),
  };
};
